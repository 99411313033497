import React from 'react'
import leftImage from '../../static/img/about-1.jpg'
import rightImage from '../../static/img/about-2.jpg'

export const About = () => (
  <section className="about">
    <div className="block">
      <img
        src={leftImage}
        className="about__image"
        alt="Over klusbedrijf Maric"
      />
      <div className="about__text">
        <h1>Over ons</h1>
        <p>
          <strong>
            Mijn naam is Mario Maric. Van jongs af aan ben ik betrokken geweest
            bij het klusbedrijf van mijn vader, ik leerde al snel de kneepjes
            van het vak. Doordat ik in deze omgeving ben opgegroeid heb ik een
            grote passie ontwikkeld voor het klussen. Ik ben zeer resultaat
            gericht en een pietje precies wanneer het om afwerking gaat.
          </strong>
        </p>
      </div>
    </div>
    <div className="block">
      <div className="about__text">
        <p>
          Na Veel werkervaring opgedaan te hebben, heb ik besloten mijn eigen
          klusbedrijf te starten. Met oog voor detail en kwaliteit wil ik mijn
          passie overdragen in mijn werk en bedrijf. Klusbedrijf Maric is een
          allround klusbedrijf, wat betekent dat ik in principe iedere klus kan
          realiseren. Dit is natuurlijk afhankelijk van de omvang van de
          werkzaamheden. Aangezien het bedrijf een eenmanszaak is neem ik bij
          grotere klussen freelance klussers aan. Deze selecteer ik zelf op
          kwaliteit en betrouwbaarheid, zodat u zich daar geen zorgen over hoeft
          te maken.
        </p>
      </div>
      <img
        src={rightImage}
        className="about__image"
        alt="Over klusbedrijf Maric"
      />
    </div>
  </section>
)
