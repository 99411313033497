import React from "react"
import Layout from "../components/layout"
import { About } from "../components/about/About"
import { Contact } from "../components/contact/Contact"
import SEO from "../components/seo"

const OverOnsPage = () => (
  <Layout>
    <SEO title="Over ons" />
    <About />
    <Contact />
  </Layout>
)

export default OverOnsPage
